import React, { useEffect } from "react";
import Data_Protection_Policy_Content from "./Data_Protection_Policy_Content/Data_Protection_Policy_Content";
import Data_Protection_Policy_Banner from "./Data_Protection_Policy_Banner/Data_Protection_Policy_Banner";

function Data_Protection_Policy() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <Data_Protection_Policy_Banner />
      <Data_Protection_Policy_Content />
    </>
  );
}

export default Data_Protection_Policy;
