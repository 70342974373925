import React, { useState } from "react";
import Breadcrumbs from "../../CommonComponents/Breadcrumbs/Breadcrumbs";

function Dispute_Resolution_Chargeback_Policy_Banner() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "Dispute Resolution and Chargeback Policy", link: "/dispute-resolution-chargeback-policy", className:"active" },
    ,
  ]);
  return (
    <>
      <section className=" Privacy_Policy Return_Policy_Banner">
        <div className="Banner_holder">
          <div className="container">
            <div className="text-holder ">
              <div>
                <Breadcrumbs items={breadcrumbItems} />
              </div>
              <h1 className="title">Dispute Resolution and Chargeback Policy</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dispute_Resolution_Chargeback_Policy_Banner;
