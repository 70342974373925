import React, { useEffect } from "react";
import Legal_Notice_Content from "./Legal_Notice_Content/Legal_Notice_Content";
import Legal_Notice_Banner from "./Legal_Notice_Banner/Legal_Notice_Banner";

function Legal_Notice() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <Legal_Notice_Banner />
      <Legal_Notice_Content />
    </>
  );
}

export default Legal_Notice;
