import React, { useEffect } from "react";
import Dispute_Resolution_Chargeback_Policy_Banner from "./Dispute_Resolution_Chargeback_Policy_Banner/Dispute_Resolution_Chargeback_Policy_Banner";
import Dispute_Resolution_Chargeback_Policy_Content from "./Dispute_Resolution_Chargeback_Policy_Content/Dispute_Resolution_Chargeback_Policy_Content";

function Dispute_Resolution_Chargeback_Policy() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <Dispute_Resolution_Chargeback_Policy_Banner />
      <Dispute_Resolution_Chargeback_Policy_Content />
    </>
  );
}

export default Dispute_Resolution_Chargeback_Policy;
