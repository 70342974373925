import React, { useContext, useEffect, useState } from "react";
import Button_Common from "../../CommonComponents/Button_Common/Button_Common";
import { Context } from "../../../utils/context";
import { ReturnPolicy } from "../../../utils/apis/master/Master";
function Cookie_Policy_Content() {
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState({});

  const getData = async () => {
    const res = await ReturnPolicy();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="Privacy_Policy_Content">
        <div className="container">
          <div className="Privacy_Policy_Content_holder">
            <div className="list-holder">
              
              <div className="text-holder">
                <p className="mini-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic types- -etting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldu PageMaker including versions of Lorem Ipsum.
                </p>
                <p className="mini-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic types- -etting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldu PageMaker including versions of Lorem Ipsum.
                </p>
              </div>

              <div className="text-holder">
                <h3 className="privacy">1. WHAT INFORMATION WE COLLECT</h3>
                <p className="mini-text">
                  Creating an account at MN is entirely voluntary. Should you
                  choose to create an account with MN, you will be requested to
                  provide certain personally identifiable information (PII),
                  such as your name, address, phone number, email address,
                  username, password, and company of employment ("Information").
                  Additionally, we collect the physical and/or email addresses
                  of your registered Recipients. By providing Information, you
                  confirm that it is accurate & authentic, and that you have the
                  authorization to share it with MN and our third-party
                  Marketplace sellers involved in fulfilling your orders.
                </p>

                <p className="mini-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic types- -etting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldu PageMaker including versions of Lorem Ipsum.
                </p>

                <p className="mini-text">
                  Survey Information is handled similarly to other Information.
                  If you reach out to us by phone for customer support, please
                  note that such calls may be recorded for quality assurance and
                  training purposes.
                </p>
              </div>

              <div className="text-holder">
                <h3 className="privacy">
                  2. ACCESS TO & CHOICES ABOUT YOUR INFORMATION
                </h3>
                <p className="mini-text">
                  You have the option to: (1) opt out of receiving promotional
                  materials via mail, phone, or email; (2) access, review,
                  verify, update, or rectify your personal Information; or (3)
                  deactivate your account. To exercise any of these options,
                  just get in touch with MCW, as detailed herein. You can
                  unsubscribe from marketing emails by visiting the
                  Communication Preferences page.
                </p>
              </div>
              <div className="text-holder">
                <h3 className="privacy">3. SECURITY</h3>
                <p className="mini-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic types- -etting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldu PageMaker including versions of Lorem Ipsum.
                </p>
                <p className="mini-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic types- -etting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldu PageMaker including versions of Lorem Ipsum.
                </p>
              </div>
              <div className="Buttons">
                <Button_Common text="Decline" className="decline" />
                <Button_Common text="Accept" className="accept" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cookie_Policy_Content;
