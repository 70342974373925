import React, { useEffect } from "react";
import Payment_Policy_Banner from "./Payment_Policy_Banner/Payment_Policy_Banner";
import Payment_Policy_Content from "./Payment_Policy_Content/Payment_Policy_Content";

function Payment_Policy() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <Payment_Policy_Banner/>
      <Payment_Policy_Content />
    </>
  );
}

export default Payment_Policy;
