import React, { useEffect } from "react";
import Cookie_Policy_Banner from "./Cookie_Policy_Banner/Cookie_Policy_Banner";
import Cookie_Policy_Content from "./Cookie_Policy_Content/Cookie_Policy_Content";

function Cookie_Policy() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <Cookie_Policy_Banner/>
      <Cookie_Policy_Content />
    </>
  );
}

export default Cookie_Policy;
