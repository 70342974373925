import React, { useEffect } from "react";
import Security_Policy_Banner from "./Security_Policy_Banner/Security_Policy_Banner";
import Security_Policy_Content from "./Security_Policy_Content/Security_Policy_Content";

function Security_Policy() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <Security_Policy_Banner/>
      <Security_Policy_Content/>
    </>
  );
}

export default Security_Policy;
